




















import AppSetupCard from '@/components/shared/SetupCard.vue';
import { defineComponent } from '@vue/composition-api';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  components: { AppSetupCard },
  setup() {
    const login = () => {
      useAuthStore().goToLogin('login');
    };

    return {
      login,
    };
  },
});
