











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppSetupCard',
  props: {
    src: String,
    alt: String,
    title: String,
    subTitle: String,
  },
});
